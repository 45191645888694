import React, { useRef } from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import aboutStyles from "./about.module.scss"
import profile from "../assets/profile.jpg"
import { Link } from "gatsby"
import { motion } from "framer-motion"

const AboutPage = () => {
  let logoElement = useRef(null)

  return (
    <>
      <Layout>
        <Head title="About" />
        <motion.div
          className={aboutStyles.aboutContainer}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <div className={aboutStyles.goBack}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <polygon points="3.828 9 9.899 2.929 8.485 1.515 0 10 .707 10.707 8.485 18.485 9.899 17.071 3.828 11 20 11 20 9 3.828 9" />
            </svg>
            <Link to="/" className={aboutStyles.goBackLink}>
              Go back
            </Link>
          </div>
          <h1 className="page-title">About Me</h1>
          <div class="flex">
            <p className={aboutStyles.about}>
              Hi! I'm a web developer currently based in New York. I'm
              interested in building web experiences that can positively impact
              communities. I'm striving to achieve this by creating performant
              websites and learning about accessibility. I enjoy learning new
              technologies and tools, and am looking for ways to use my
              background in education and psychology in my work.
            </p>
          </div>
        </motion.div>
      </Layout>
    </>
  )
}

export default AboutPage
